import React, { Fragment, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  TextField,
  Button,
  Grid,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Dialog,
  Switch,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CustomDatePicker from '../../helpers/DatePicker';
import axios from 'axios';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '370px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const schema = yup.object().shape({
  startdate: yup.string().required('Starting date is required'),
  enddate: yup.string().required('Ending Date is required'),
  comments: yup.string().required('Comments are required'),
});

const ControlForm = (props) => {
  const AuthHeaderRef = useRef({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const switchValue = watch('customSwitch', false);

  useEffect(() => {
    setValue('startdate', props.currentData?.startdate);
    setValue('enddate', props.currentData?.enddate);
    setValue('comments', props.currentData?.comments);
    setValue('period', props.currentData?.period);
    // setValue('status', props.currentData?.status);
  }, [props.currentData]);

  const API_URL = process.env.REACT_APP_API_URL;
  const onSubmit = async (data) => {
    if (props.currentData && props.currentData.id) {
      try {
        const localAuthHeader = AuthHeaderRef.current;
        props.setReloading(true);
        setTimeout(async () => {
          const state = {
            period: data.period,
            startDate: data.startdate,
            endDate: data.enddate,
            comments: data.comments,
            status: false,
            // status: data.status,
          };

          await axios.put(
            `${API_URL}/controlpanel/` + props.currentData.id,
            state,
            localAuthHeader
          );
          props.setReloading(false);
          props.setAlert({
            open: true,
            message: 'Data updated successfully',
            severity: 'success',
          });
          props.setCurrentData(props.initialCurrentData);
        }, 1000);
      } catch (error) {
        console.error('Error adding data:', error);
        props.setReloading(false);
      }
      props.setOpen(false);
    } else {
      try {
        const localAuthHeader = AuthHeaderRef.current;
        props.setReloading(true);
        setTimeout(async () => {
          const state = {
            period: data.period,
            startDate: data.startdate,
            endDate: data.enddate,
            comments: data.comments,
            status: false,
            // status: data.status,
          };

          await axios.post(`${API_URL}/controlpanel`, state, localAuthHeader);
          props.setReloading(false);
          props.setAlert({
            open: true,
            message: 'Data added successfully',
            severity: 'success',
          });
        }, 1000);
      } catch (error) {
        console.error('Error adding data:', error);
        props.setReloading(false);
      }
      props.setOpen(false);
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle
          id="expnses-add-titel"
          style={{
            backgroundColor: '#1976d2',
            marginBottom: '15px',
            padding: '4px',
            color: 'white',
            textAlign: 'center',
            fontSize: '15px',
          }}
        >
          Control Panel
          <CloseIcon
            id="expenses-close-icon"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 2,
              marginTop: '1px',
              marginRight: '5px',
              fontSize: '20px',
              color: 'grey.400',
              '&:hover': {
                color: '#f94449',
              },
            }}
          />
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', padding: '8px' }}
            >
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="period"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        id="period"
                        label="Period"
                        {...field}
                        error={Boolean(errors.period)}
                        helperText={errors.period?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomDatePicker
                    label="Start Date"
                    name="startdate"
                    control={control}
                    disablePast={true}
                    error={errors.startdate}
                    className="date"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomDatePicker
                    label="End Date"
                    name="enddate"
                    control={control}
                    disablePast={true}
                    error={errors.enddate}
                    className="date"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="comments"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="update-comments"
                        label="Comments"
                        multiline
                        rows={2}
                        {...field}
                        error={Boolean(errors.comments)}
                        helperText={errors.comments?.message}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <Switch {...field} checked={field.value} />
                            )}
                          />
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" sx={{ ml: 2 }}>
                        {switchValue ? 'Active' : 'Inactive'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ mr: 1, p: 1 }}>
            {/* <Button
              autoFocus
              variant="outlined"
              onClick={handleClose}
              id="expenses-cancel-btn"
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button> */}
            <Button
              autoFocus
              variant="contained"
              type="submit"
              id="update-submit-btn"
              sx={{
                textTransform: 'none',
                fontSize: '11px',
                p: '5px',
                backgroundColor: '#1976d2',
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </Fragment>
  );
};

export default ControlForm;
