const SUBJECT = [
  "all",
  "dashboard",
  "aid",
  "reports",
];
const ABILITIES = [
  "manage",
  "create",
  "update",
  "delete",
  "view",  
  "readAll",
  "readById",
  "visible"
];

export { SUBJECT, ABILITIES };
