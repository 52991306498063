// import * as React from "react";
// import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import Chip from "@mui/material/Chip";
// import { tamilNaduDistricts } from "../../../../comman/districts";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

// export default function MultipleSelectChip({ setDistrict, district }) {
//   const theme = useTheme();

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setDistrict(
//       // On autofill we get a stringified value.
//       typeof value === "string" ? value.split(",") : value
//     );
//   };

//   return (
//     <div>
//       <FormControl size="small" fullWidth sx={{}}>
//         <InputLabel id="demo-multiple-chip-label">District</InputLabel>
//         <Select
//           labelId="demo-multiple-chip-label"
//           id="demo-multiple-chip"
//           multiple
//           value={district}
//           onChange={handleChange}
//           input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
//           renderValue={(selected) => (
//             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
//               {selected.map((value) => (
//                 <Chip key={value} label={value} />
//               ))}
//             </Box>
//           )}
//           MenuProps={MenuProps}
//         >
//           {tamilNaduDistricts.map((name) => (
//             <MenuItem
//               key={name}
//               value={name}
//               style={getStyles(name, district, theme)}
//             >
//               {name}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }

import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { tamilNaduDistricts } from '../../../../comman/districts';

export default function MultipleSelectChip({ setDistrict, district }) {
  const handleChange = (event, value) => {
    setDistrict(Array.isArray(value) ? value : []); // Ensure value is an array
  };

  return (
    <Stack>
      <Autocomplete
        fullWidth
        size="small"
        multiple
        id="tags-outlined"
        options={tamilNaduDistricts}
        getOptionLabel={(option) => option}
        value={district} // Set the value prop to the district state
        onChange={handleChange} // Handle the onChange event
        defaultValue={district}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label="Districts" placeholder="select" />
        )}
      />
    </Stack>
  );
}
