import React, { Fragment, useState, useEffect, useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  TextField,
  Dialog,
  Button,
  InputAdornment,
  Chip,
  Tooltip,
} from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import SnackBar from '../../../helpers/SnackBar';
import DeleteUser from '../../../helpers/DeleteHelpers';
import UserValidation from './UserValidation';
import Axios from 'axios';
import '../../../style/Setting.css';
import AddIcon from '@mui/icons-material/Add';
import ClockLoader from 'react-spinners/ClockLoader';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewUser from './ViewUser';

const UserList = ({ drawer }) => {
  const AuthHeaderRef = useRef({
    headers: {
      'Content-Type': 'application/json',
      // Authorization: 'Bearer' + ' ' + `${localStorage.getItem('token')}`,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [snackDelete, setSnackDelete] = useState(false);
  const [snackAdd, setSnackAdd] = useState(false);
  const [snackUpdate, setSnackUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const UsersData = [];
  const [listall, setListAll] = useState([]);
  const [checkRole, setCheckRole] = useState([]);
  const [treasurerUserRole, setTreasurerUserRole] = useState(false);
  const [committeeHeadUserRole, setCommitteeHeadUserRole] = useState(false);
  const [dispersorUserRole, setDispersorUserRole] = useState(false);
  const [doubleUserRole, setDoubleUserRole] = useState(false);
  const [tripleUserRole, setTripleUserRole] = useState(false);
  const [CmhdUserRole, setCmhdUserRole] = useState(false);
  const [dtUserRole, setDtUserRole] = useState(false);
  const [error, setError] = useState(null);
  //list all the user
  const [abilityList, setAbilityList] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL;

  //Open popup
  const handleClickOpen = () => {
    setOpen(true);
    setAdd(true);
    let isTreasurerUserRole = false;
    let isDispersorUserRole = false;
    let isCommitteeHeadUserRole = false;
    let isDoubleUserRole = false;
    let iscmhdUserRole = false;
    let isdtUserRole = false;
    let isTripleUserRole = false;
    let hasTreasurerRole = false;
    let hasDispersorRole = false;
    let hasCommitteHeadRole = false;
    checkRole.forEach((user) => {
      abilityList.forEach((name) => {
        if (user === name._id) {
          if (name.roleName === 'treasurer') {
            // isTreasurerUserRole = true;
            hasTreasurerRole = true;
          } else if (name.roleName === 'committee head') {
            hasCommitteHeadRole = true;
          } else if (name.roleName === 'dispersor') {
            hasDispersorRole = true;
          }
        }
      });
    });
    if (hasCommitteHeadRole && hasDispersorRole && hasTreasurerRole) {
      isTripleUserRole = true;
    } else if (hasTreasurerRole && hasCommitteHeadRole) {
      isDoubleUserRole = true;
    } else if (hasCommitteHeadRole && hasDispersorRole) {
      iscmhdUserRole = true;
    } else if (hasDispersorRole && hasTreasurerRole) {
      isdtUserRole = true;
    } else if (hasTreasurerRole) {
      isTreasurerUserRole = true;
    } else if (hasCommitteHeadRole) {
      isCommitteeHeadUserRole = true;
    } else if (hasDispersorRole) {
      isDispersorUserRole = true;
    }
    setTreasurerUserRole(isTreasurerUserRole);
    setCommitteeHeadUserRole(isCommitteeHeadUserRole);
    setDispersorUserRole(isDispersorUserRole);
    setDoubleUserRole(isDoubleUserRole);
    setTripleUserRole(isTripleUserRole);
    setCmhdUserRole(iscmhdUserRole);
    setDtUserRole(isdtUserRole);
  };
  //Close popup
  const handleClose = () => {
    setOpen(false);
    setAdd(false);
    setDeletes(false);
    setTreasurerUserRole(false);
    setCommitteeHeadUserRole(false);
    setDoubleUserRole(false);
  };

  const fetchData = async (
    API_URL,
    AuthHeaderRef,
    setListAll,
    setLoading,
    navigate,
    setAbilityList
  ) => {
    const delay = 2000;

    // Use setTimeout to hide the CircularProgress after the delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    try {
      let extractRoleIds = [];
      const localAuthHeader = AuthHeaderRef.current;
      await Axios.get(`${API_URL}/users`, localAuthHeader).then((res) => {
        setListAll(res.data.result);
        res.data.result.length > 0
          ? res.data.result.forEach((role) =>
              extractRoleIds.push(...role.roleId)
            )
          : setCheckRole([]);
      });
      setCheckRole(extractRoleIds);
      await Axios.get(`${API_URL}/permissions`, localAuthHeader).then((res) => {
        setAbilityList(res.data.result);
      });
    } catch (err) {
      console.log(err);
    }
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    fetchData(
      API_URL,
      AuthHeaderRef,
      setListAll,
      setLoading,
      navigate,
      setAbilityList
    );
  }, [API_URL, navigate]);

  const [users, setUsers] = useState(UsersData);
  const [searchTerm, setSearchTerm] = useState('');
  const initialFormState = {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    code: '',
    phone_number: '',
    role_name: [],
    district: [],
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [deletes, setDeletes] = useState(false);
  const [deleteId, setDeleteId] = useState(1);
  const [editId, setEditId] = useState(null);
  const [view, setView] = useState('');
  const handleEditClick = (event, user) => {
    event.preventDefault();
    setOpen(true);
    setEditId(user._id);
    setCurrentUser({
      id: user._id,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      code: user.countryCode,
      phone_number: user.phoneNumber,
      role_name: user.roleId,
      district: user.district,
      checked: user.status,
    });
    let isTreasurerUserRole = false;
    let isDispersorUserRole = false;
    let isCommitteeHeadUserRole = false;
    let isDoubleUserRole = false;
    let hasTreasurerRole = false;
    let hasDispersorRole = false;
    let hasCommitteHeadRole = false;
    checkRole.forEach((user) => {
      abilityList.forEach((name) => {
        if (user === name._id) {
          if (name.roleName === 'treasurer') {
            // isTreasurerUserRole = true;
            hasTreasurerRole = true;
          } else if (name.roleName === 'committee head') {
            hasCommitteHeadRole = true;
          } else if (name.roleName === 'dispersor') {
            hasDispersorRole = true;
          }
        }
      });
    });
    if (hasTreasurerRole && hasCommitteHeadRole && hasDispersorRole) {
      isDoubleUserRole = true;
    } else if (hasTreasurerRole) {
      isTreasurerUserRole = true;
    } else if (hasCommitteHeadRole) {
      isCommitteeHeadUserRole = true;
    } else if (hasDispersorRole) {
      isDispersorUserRole = true;
    }
    setTreasurerUserRole(isTreasurerUserRole);
    setDispersorUserRole(isDispersorUserRole);
    setCommitteeHeadUserRole(isCommitteeHeadUserRole);
    setDoubleUserRole(isDoubleUserRole);
  };

  const handleViewClick = (event, user) => {
    event.preventDefault();
    setViewOpen(true);
    setView({
      id: user._id,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      code: user.countryCode,
      phone_number: user.phoneNumber,
      role_name: user.roleId,
      district: user.district,
      checked: user.status,
    });
  };

  const handleDelete = (id) => {
    setOpen(true);
    setDeletes(true);
    setDeleteId(id);
  };
  const handleEvent = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleReset = () => {
    setSearchTerm('');
  };

  /**
   * Deletes the specified item from the collection.
   */

  const handleDeletes = async (deleteId) => {
    try {
      await Axios.delete(
        `${API_URL}/users/` + deleteId,
        AuthHeaderRef.current
      ).then(({ data }) => {
        console.log(data);
      });
    } catch (error) {
      setError('Failed to delete data');
    }
    handleClose();
    setOpen(false);
    setDeletes(false);
    setEditId(null);
    setSnackDelete(true);
  };

  /**
   * Adds a new item to the collection.
   */

  function addUser(
    firstName,
    lastName,
    email,
    code,
    phono,
    password,
    role,
    checked,
    district
  ) {
    const handleInsert = {
      firstName: firstName,
      lastName: lastName,
      userName: firstName + lastName,
      email: email,
      password: password,
      countryCode: code,
      phoneNumber: phono,
      roleId: role,
      status: checked,
      district: district,
    };
    try {
      Axios.post(`${API_URL}/users`, handleInsert, AuthHeaderRef.current).then(
        (res) => console.log('Successfully Inserted the User')
      );
      setTreasurerUserRole(false);
      setCommitteeHeadUserRole(false);
      setDoubleUserRole(false);
    } catch (err) {
      setTreasurerUserRole(false);
      setCommitteeHeadUserRole(false);
      setDoubleUserRole(false);
      console.log('ERROR', err);
    }
    setSnackAdd(true);
  }

  /**
   * Update a new item to the collection.
   */

  function handleUpdate(
    id,
    firstName,
    lastName,
    email,
    code,
    phono,
    role,
    checked,
    district
  ) {
    const handleUpdate = {
      _id: id,
      firstName: firstName,
      lastName: lastName,
      userName: firstName + lastName,
      email: email,
      countryCode: code,
      phoneNumber: phono,
      roleId: role,
      status: checked,
      district: district,
    };
    try {
      const res = Axios.patch(
        `${API_URL}/users/` + id,
        handleUpdate,
        AuthHeaderRef.current
      );
      console.log('successfully updated the User', res);
      setTreasurerUserRole(false);
      setCommitteeHeadUserRole(false);
      setDoubleUserRole(false);
    } catch (err) {
      console.log('ERROR', err);
      setTreasurerUserRole(false);
      setCommitteeHeadUserRole(false);
      setDoubleUserRole(false);
    }
    setEditId(null);
    handleClose();
    setSnackUpdate(true);
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleSnackbarClose = () => {
    setSnackDelete(false);
    setSnackUpdate(false);
    setSnackAdd(false);
    // Refresh the table data here
    setLoading(true); // Set loading state to true to show spinner
    fetchData(
      API_URL,
      AuthHeaderRef,
      setListAll,
      setLoading,
      navigate,
      setAbilityList
    ); // Fetch data again to refresh the table
  };
  return (
    <>
      <ViewUser
        open={viewOpen}
        handleClose={() => setViewOpen(false)}
        view={view}
        abilityList={abilityList}
      />
      {snackDelete ? (
        <SnackBar
          title="User deleted successfully"
          severity="error"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackAdd ? (
        <SnackBar
          title="User Added successfully"
          severity="success"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackUpdate ? (
        <SnackBar
          title="User Updated successfully"
          severity="info"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        id="grid-margin"
      >
        <Box gridColumn="span 2" gridRow="span 3">
          <TextField
            // label="Search..."
            id="outlined-start-adornment"
            size="small"
            onChange={handleEvent}
            placeholder="Search..."
            className="textfield-search-label"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>
        <Box gridColumn="span 2" gridRow="span 3">
          {drawer ? (
            <Button
              className="button"
              id="reset-button-drawer"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          ) : (
            <Button
              className="button"
              id="reset-button"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          )}
        </Box>
        <Box gridColumn="span 8" gridRow="span 3">
          {drawer ? (
            <Button
              id="add-button-drawer"
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          ) : (
            <Button
              id="add-button"
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          )}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        {deletes ? (
          <DeleteUser
            title="Delete"
            content="Are you sure to delete?"
            setOpen={setOpen}
            setDeletes={setDeletes}
            setUsers={setUsers}
            users={users}
            deleteId={deleteId}
            handleDeletes={handleDeletes}
            handleClose={handleClose}
          />
        ) : null}
        {add ? (
          <UserValidation
            addUser={addUser}
            handleClose={handleClose}
            abilityList={abilityList}
            treasurerUserRole={treasurerUserRole}
            dispersorUserRole={dispersorUserRole}
            committeeHeadUserRole={committeeHeadUserRole}
            doubleUserRole={doubleUserRole}
            tripleUserRole={tripleUserRole}
            CmhdUserRole={CmhdUserRole}
            dtUserRole={dtUserRole}
          />
        ) : null}
        {editId ? (
          <UserValidation
            currentUser={currentUser}
            abilityList={abilityList}
            setUsers={setUsers}
            handleClose={handleClose}
            handleUpdate={handleUpdate}
            setEditId={setEditId}
            users={users}
            editId={editId}
            treasurerUserRole={treasurerUserRole}
            dispersorUserRole={dispersorUserRole}
            committeeHeadUserRole={committeeHeadUserRole}
            doubleUserRole={doubleUserRole}
            tripleUserRole={tripleUserRole}
            CmhdUserRole={CmhdUserRole}
            dtUserRole={dtUserRole}
          />
        ) : null}
      </Dialog>
      {/* TABLE */}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <ClockLoader
            color="#1976d2"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <TableContainer component={Paper} id="table-container">
          <Table id="table" size="small" aria-label="a dense table">
            <TableHead id="table-header">
              <TableRow>
                <TableCell id="table-header-align">First Name</TableCell>
                <TableCell id="table-header-align">Last Name</TableCell>
                <TableCell id="table-header-align">District</TableCell>
                {/* <TableCell id="table-header-align">Email</TableCell> */}
                {/* <TableCell id="table-header-align">Code</TableCell> */}
                {/* <TableCell id="table-header-align">Contact Number</TableCell> */}
                <TableCell id="table-header-align">Roles</TableCell>
                <TableCell id="table-header-align">Status</TableCell>
                <TableCell id="table-header-align">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listall.length > 0 ? (
                listall
                  .filter(
                    (user) =>
                      user.firstName &&
                      user.firstName.toLowerCase().includes(searchTerm)
                  )
                  .map((user) => (
                    <Fragment>
                      <TableRow key={user._id}>
                        <TableCell>{user.firstName}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>
                          {user.district.map((name) => (
                            <Chip key={name} label={name} />
                          ))}
                        </TableCell>
                        {/* <TableCell>{user.email}</TableCell> */}
                        {/* <TableCell>{user.countryCode}</TableCell> */}
                        {/* <TableCell>{user.phoneNumber}</TableCell> */}
                        <TableCell>
                          {abilityList.map((name) =>
                            user.roleId.includes(name._id) ? (
                              <Chip key={name._id} label={name.roleName} />
                            ) : null
                          )}
                        </TableCell>
                        {user.status === true ? (
                          <TableCell>Active</TableCell>
                        ) : (
                          <TableCell>InActive</TableCell>
                        )}
                        <TableCell>
                          <Tooltip title="Edit">
                            <EditIcon
                              style={{ fontSize: '1.3rem', margin: '3px' }}
                              id="edit-button"
                              onClick={(event) => handleEditClick(event, user)}
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              style={{ fontSize: '1.3rem', margin: '3px' }}
                              id="delete-button"
                              onClick={() => handleDelete(user._id)}
                            />
                          </Tooltip>
                          <Tooltip title="View Details">
                            <VisibilityIcon
                              sx={{
                                color: 'green',
                                cursor: 'pointer',
                                fontSize: '1.3rem',
                                margin: '3px',
                              }}
                              onClick={(event) => handleViewClick(event, user)}
                            />
                          </Tooltip>
                          <Tooltip title={user.email}>
                            <EmailIcon
                              sx={{
                                color: '#3396FF',
                                cursor: 'pointer',
                                fontSize: '1.3rem',
                                margin: '3px',
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            title={user.countryCode + ' ' + user.phoneNumber}
                          >
                            <PhoneAndroidIcon
                              sx={{
                                color: '#65DF5B',
                                cursor: 'pointer',
                                fontSize: '1.3rem',
                                margin: '3px',
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
              ) : (
                <TableRow>
                  <td colSpan={7}>No Users</td>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default UserList;
