import React, { Fragment, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogTitle,
  DialogContent,
  Box,
  Dialog,
  Button,
  Typography,
  Grid,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../../style/Setting.css';

//API URL

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    // width: '770px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ViewUser = (props) => {
  return (
    <Fragment>
      <BootstrapDialog onClose={props.handleClose} open={props.open}>
        <DialogTitle className="dialog-header">
          <p className="dialog-header-text">View</p>
          <CloseIcon onClick={props.handleClose} className="dialog-closeicon" />
        </DialogTitle>
        <Box sx={{ width: '500px', p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                First Name
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {' '}
                {props.view.first_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                Last Name
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {props.view.last_name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                Code
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {' '}
                {props.view.code}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                Contact Number
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {' '}
                {props.view.phone_number}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                Email
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {' '}
                {props.view.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                Status
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {' '}
                {props.view.checked === true ? 'Active' : 'Inactive'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                Roles
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {' '}
                {Array.isArray(props.view.role_name) &&
                  props.abilityList?.map((name) =>
                    props.view.role_name.includes(name._id) ? (
                      <Chip key={name._id} label={name.roleName} />
                    ) : null
                  )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                District
              </Typography>
              {props.view.district?.map((item, index) => (
                <Typography sx={{ fontSize: '16px' }} key={index}>
                  {item}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Box>
      </BootstrapDialog>
    </Fragment>
  );
};

export default ViewUser;
