import React, { Fragment, useState, useEffect, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, TextField, Dialog, Button, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClockLoader from 'react-spinners/ClockLoader';
import PermissionValidation from './PermissionValidation';
import SnackBar from '../../../helpers/SnackBar';
import DeleteUser from '../../../helpers/DeleteHelpers';
import Axios from 'axios';
import '../../../style/Setting.css';
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DialogModel from '../../../component/model';
import StickyTable from '../../../component/muiStickyTable';

const Permission = ({ drawer }) => {
  // const Apiurl = process.env.REACT_APP_API_URL;
  const AuthHeaderRef = useRef({
    headers: {
      'Content-Type': 'application/json',
      // Authorization: 'Bearer' + ' ' + `${localStorage.getItem('token')}`,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [snackDelete, setSnackDelete] = useState(false);
  const [snackAdd, setSnackAdd] = useState(false);
  const [snackUpdate, setSnackUpdate] = useState(false);
  const [dialogSize, setDialogSize] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleClickOpen = () => {
    setAdd(true);
    setOpen(true);
    setDialogSize(true);
    console.log('open popup', open);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogSize(false);
    setDeleteDialog(false);
  };

  const Data = [];
  const [listall, setListAll] = useState([]);
  console.log('listall', listall);
  //To list all the data from the database

  const fetchData = async (API_URL, AuthHeaderRef, setListAll, setLoading) => {
    const delay = 2000;

    // Use setTimeout to hide the CircularProgress after the delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    try {
      const localAuthHeader = AuthHeaderRef.current;
      Axios.get(`${API_URL}/permissions`, localAuthHeader).then((res) => {
        console.info('Successully getting data');
        setListAll(res.data.result);
        //console.log(res.data.result);
      });
    } catch (err) {
      console.error('Received an error', err);
    }
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    fetchData(API_URL, AuthHeaderRef, setListAll, setLoading);
  }, [API_URL]);

  const [deleteId, setDeleteId] = useState(1);
  const [deletes, setDeletes] = useState(false);
  const [users, setUsers] = useState(Data);
  const [searchTerm, setSearchTerm] = useState('');
  const initialFormState = {
    id: null,
    rolename: '',
    roleDescription: '',
    roleAbilities: [],
    checked: '',
  };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  console.log('currentUser', currentUser);
  const [editId, setEditId] = useState(null);
  console.log('Editid', editId);
  const [error, setError] = useState(null);
  const handleEvent = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleReset = () => {
    setSearchTerm('');
  };
  function addUser(roleName, roleDescription, roleAbilities, checked) {
    try {
      Axios.post(
        `${API_URL}/permissions`,
        {
          roleName,
          roleDescription,
          roleAbilities,
          roleStatus: checked,
        },
        AuthHeaderRef.current
      ).then((res) => {
        console.log('Successully added data');
        setSnackAdd(true);
        setAdd(false);
      });
    } catch (err) {
      console.log('Received an error');
      console.log('Error', err);
    }
  }
  //Delete the data from the database
  const handleDelete = (id) => {
    setOpen(true);
    setDeleteDialog(true);
    setDeletes(true);
    setDeleteId(id);
  };
  //Edit the data from the database
  const handleEditClick = (event, user) => {
    console.log('Enter handleEditClick');
    event.preventDefault();
    setEditId(user._id);
    setCurrentUser({
      id: user._id,
      roleName: user.roleName,
      roleDescription: user.roleDescription,
      roleAbilities: user.roleAbilities,
      checked: user.roleStatus,
    });
    setDialogSize(true);
    setOpen(true);
  };

  /**
   * Deletes the specified item from the collection.
   */

  const handleDeletes = async (deleteId) => {
    try {
      console.log('DELETE', deleteId);
      await Axios.delete(
        `${API_URL}/permissions/` + deleteId,
        AuthHeaderRef.current
      ).then(({ data }) => {
        console.log(data);
      });
    } catch (error) {
      setError('Failed to delete data');
    }
    handleClose();
    setOpen(false);
    setDeletes(false);
    setSnackDelete(true);
  };
  function handleUpdate(id, roleName, roleDescription, roleAbilities, checked) {
    console.log('Enter the last');
    const handleSave = {
      _id: id,
      roleName,
      roleDescription,
      roleAbilities,
      roleStatus: checked,
    };
    try {
      Axios.patch(
        `${API_URL}/permissions/` + id,
        handleSave,
        AuthHeaderRef.current
      );
    } catch (err) {
      console.log('Received an error');
      console.log('Error', err);
    }
    setEditId(null);
    handleClose();
    setSnackUpdate(true);
  }

  const handleSnackbarClose = () => {
    setSnackDelete(false);
    setSnackUpdate(false);
    setSnackAdd(false);
    // Refresh the table data here
    setLoading(true); // Set loading state to true to show spinner
    fetchData(API_URL, AuthHeaderRef, setListAll, setLoading); // Fetch data again to refresh the table
  };

  return (
    <>
      {snackDelete ? (
        <SnackBar
          title="Permission deleted successfully"
          severity="error"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackAdd ? (
        <SnackBar
          title="Permission Added successfully"
          severity="success"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      {snackUpdate ? (
        <SnackBar
          title="Permission Updated successfully"
          severity="info"
          vertical="top"
          horizontal="center"
          onClose={handleSnackbarClose}
        />
      ) : (
        <></>
      )}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
        <Box gridColumn="span 4" display="flex" fontWeight="bold"></Box>
        <Box gridColumn="span 6" display="flex"></Box>
        <Box gridColumn="span 2" display="flex" margin="10px"></Box>
        <Box gridColumn="span 2" gridRow="span 3">
          <TextField
            // label="Search..."
            id="outlined-start-adornment"
            size="small"
            onChange={handleEvent}
            placeholder="Search..."
            className="textfield-search-label"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Box>
        <Box gridColumn="span 2" gridRow="span 3">
          {drawer ? (
            <Button
              className="button"
              id="reset-button-drawer"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          ) : (
            <Button
              className="button"
              id="reset-button"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
            >
              Reset
            </Button>
          )}
        </Box>
        <Box gridColumn="span 8" gridRow="span 3">
          {drawer ? (
            <Button
              variant="contained"
              id="add-button-drawer-role"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          ) : (
            <Button
              variant="contained"
              id="add-button-role"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add
            </Button>
          )}
        </Box>
      </Box>
      {dialogSize ? (
        <Dialog
          open={open}
          onClose={handleClose}
          classes={{ paper: 'custom-dialog' }}
        >
          {add ? (
            <PermissionValidation
              setAdd={setAdd}
              addUser={addUser}
              handleClose={handleClose}
            />
          ) : null}
          {editId ? (
            <PermissionValidation
              currentUser={currentUser}
              handleUpdate={handleUpdate}
              setUsers={setUsers}
              setEditId={setEditId}
              users={users}
              handleClose={handleClose}
            />
          ) : null}
        </Dialog>
      ) : null}
      {deleteDialog ? (
        <Dialog open={open} onClose={handleClose}>
          {deletes ? (
            <DeleteUser
              title="Delete"
              content="Are you sure to delete?"
              setOpen={setOpen}
              setDeletes={setDeletes}
              setUsers={setUsers}
              users={users}
              deleteId={deleteId}
              handleDeletes={handleDeletes}
              handleClose={handleClose}
            />
          ) : null}
        </Dialog>
      ) : null}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <ClockLoader
            color="#1976d2"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <TableContainer component={Paper} id="table-container">
          <Table id="table" size="small" aria-label="a dense table">
            <TableHead id="table-header">
              <TableRow>
                <TableCell id="table-header-align">Role</TableCell>
                <TableCell id="table-header-align">Description</TableCell>
                <TableCell id="table-header-align">Status</TableCell>
                <TableCell
                  id="table-header-align"
                  sx={{
                    textAlign: 'center',
                    width: '30%',
                  }}
                >
                  Abilites
                </TableCell>
                <TableCell id="table-header-align">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listall.length > 0 ? (
                listall
                  .filter((user) =>
                    user.roleName.toLowerCase().includes(searchTerm)
                  )
                  .map((user) => (
                    <Fragment>
                      <TableRow key={user._id}>
                        <TableCell>{user.roleName}</TableCell>
                        <TableCell>
                          {user.roleDescription}
                          {/* {user.urls.map((item) => (
                            <div key={item}>{item}</div>
                          ))} */}
                        </TableCell>
                        {user.roleStatus === true ? (
                          <TableCell>Active</TableCell>
                        ) : (
                          <TableCell>InActive</TableCell>
                        )}
                        <TableCell
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <DialogModel title="Abilities">
                            <StickyTable
                              header1="Subject"
                              header2="Actions"
                              rows={user.roleAbilities}
                            />
                          </DialogModel>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <EditIcon
                              id="edit-button"
                              onClick={(event) => handleEditClick(event, user)}
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              id="delete-button"
                              onClick={() => handleDelete(user._id)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
              ) : (
                <TableRow>
                  <TableCell>No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default Permission;
