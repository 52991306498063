import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Stack,
  Grid,
  Dialog,
} from '@mui/material';
import enUS from 'date-fns/locale/en-US';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlForm from './ControlForm';
import Alerts from '../../helpers/Alerts';
import DeletePopup from '../../helpers/DeletePopup';
import Axios from 'axios';

const StyleDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-columnHeaders': {
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(15),
  },
  '& .MuiDataGrid-footer': {
    borderTop: 'none',
  },
  '& .MuiDataGrid-root': {
    height: '100%', // Setting height to 100% of the container
    overflowY: 'auto', // Allowing vertical scrolling
  },
}));

function Control() {
  const AuthHeaderRef = useRef({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const [reloading, setReloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [listAll, setListAll] = useState([]);
  console.log('LISTALL', listAll);
  const initialCurrentData = {
    id: '',
    period: '',
    startdate: '',
    enddate: '',
    comments: '',
    // status: '',
  };
  const [currentData, setCurrentData] = useState(initialCurrentData);
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const timer = setTimeout(async () => {
        try {
          const localAuthHeader = AuthHeaderRef.current;
          const response = await Axios.get(
            `${API_URL}/controlpanel`,
            localAuthHeader
          );
          console.log('RES', response);
          setListAll(response.data.result);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error fetching data:', error);
        }
      }, 1500);
      return () => clearTimeout(timer);
    };
    fetchData();
  }, [reloading]);

  const handleEdit = (data) => {
    console.log('DATA', data);
    setCurrentData({
      id: data.id,
      period: data.period,
      startdate: data.startdate,
      enddate: data.enddate,
      comments: data.comments,
      // status: data.status,
    });
    setOpen(true);
  };

  const handleDelete = (details) => {
    setDeleteId(details.id);
    setDeleteOpen(true);
  };

  const handleClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteApi = async () => {
    try {
      const localAuthHeader = AuthHeaderRef.current;
      handleClose();
      setReloading(true);
      setTimeout(() => {
        Axios.delete(
          `${API_URL}/controlpanel/${deleteId}`,
          localAuthHeader
        ).then((res) => {
          setReloading(false);
          handleClose();
          setAlert({
            open: true,
            message: 'Data deleted successfully',
            severity: 'success',
          });
        });
      }, 2000);
    } catch (error) {
      setReloading(false);
      handleClose();
    }
  };

  const handleFormattedDate = (value) => {
    const formattedDate = format(new Date(value), 'dd-MMM-yyyy', {
      locale: enUS,
    });
    return formattedDate;
  };

  const handleStatus = (value) => {
    if (value === true) {
      return 'Active';
    } else if (value === false) {
      return 'InActive';
    }
  };

  const columns = [
    {
      field: 'period',
      headerName: 'Period',
      width: 130,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'startdate',
      headerName: 'Start Date',
      width: 170,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) =>
        params.value ? handleFormattedDate(params.value) : '',
    },
    {
      field: 'enddate',
      headerName: 'End Date',
      width: 170,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) =>
        params.value ? handleFormattedDate(params.value) : '',
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 170,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return params.value !== undefined ? handleStatus(params.value) : '';
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      hideable: false,
      disableRowSelectionOnClick: true,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <IconButton
              color="primary"
              disabled={params.row.status === 'paid' ? true : false}
              sx={{ ml: '3px' }}
            >
              <Tooltip title={`Update Data`}>
                <EditIcon
                  fontSize="small"
                  onClick={() => handleEdit(params.row)}
                  id="table-edit-icon"
                />
              </Tooltip>
            </IconButton>
            <IconButton color="error">
              <Tooltip title={`Delete Data`}>
                <DeleteIcon
                  onClick={() => handleDelete(params.row)}
                  id="view-icon-btn"
                />
              </Tooltip>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const rows =
    listAll &&
    listAll.map((data) => ({
      id: data._id,
      period: data.period,
      startdate: data.startDate,
      enddate: data.endDate,
      comments: data.comments,
      status: data.status,
    }));

  return (
    <div>
      <Alerts
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <Dialog open={deleteOpen} onClose={handleClose}>
        <DeletePopup
          handleDeleteApi={handleDeleteApi}
          handleClose={handleClose}
        />
      </Dialog>
      <Box sx={{ marginTop: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '17px', ml: 1 }}>
            Control Panel
          </Typography>
          <Box sx={{ mr: 1 }}>
            <Button
              variant="contained"
              sx={{ p: '3px', cursor: 'pointer' }}
              onClick={() => setOpen(true)}
            >
              ADD
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        md={12}
        style={{ height: '75vh', width: '100%', marginTop: '10px' }}
      >
        <StyleDataGrid
          density="compact"
          getRowHeight={() => 'auto'}
          loading={loading || reloading}
          //   getRowClassName="blue-header"
          rows={rows}
          columns={columns}
          hideFooter
        />
      </Grid>
      <ControlForm
        open={open}
        setOpen={setOpen}
        setAlert={setAlert}
        setReloading={setReloading}
        currentData={currentData}
        setCurrentData={setCurrentData}
        initialCurrentData={initialCurrentData}
      />
    </div>
  );
}

export default Control;
