import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  InputAdornment,
  InputLabel,
  FormControl,
  IconButton,
  OutlinedInput,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Alerts from '../helpers/snackBar.jsx';
import Logo from '../assets/idea-logo.jpg';

const theme = createTheme();
const API_URL = process.env.REACT_APP_API_URL;

export default function SignIn() {
  //
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const response = await axios.post(`${API_URL}/login`, {
        email: data.get('email'),
        password: data.get('password'),
      });
      console.log(response.data);
      setAlert({
        open: true,
        message: 'Login Successful',
        severity: 'success',
      });
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulating logout delay for 1 second
        JSON.stringify(
          localStorage.setItem('token', response.data.result.access_token)
        );
        console.log(localStorage.getItem('token'));
        navigate('/dashboard');
      } catch (error) {
        console.log(error);
      }
    } catch (err) {
      setAlert({
        open: true,
        message: 'Something went wrong. Please try again.',
        severity: 'warning',
      });
      console.log(err.response.data);
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      open: false,
      message: '',
      severity: '',
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        justifyContent="center"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            my: 12,
            mx: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #F3F6F9',
            borderRadius: '5px',
            boxShadow: '0px 0px 10px #ccc',
            padding: '2rem',
          }}
        >
          <img
            // src={
            //   require('../assets/idea-logo.jpg')
            //     .default
            // }
            src={Logo}
            alt="ideaUSAIcon"
            style={{ width: '170px', height: '74px', marginTop: '-1rem' }}
          />
          {/* <Typography
            component="h1"
            variant="h6"
            sx={{ color: 'darkslategray', marginBottom: '5px' }}
          >
            IDEA USA
          </Typography> */}
          <Typography
            sx={{
              color: 'success.main',
            }}
          >
            Admin Login
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Admin Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              color="success"
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              size="small"
              color="success"
              autoComplete="current-password"
            /> */}
            <FormControl variant="outlined" required fullWidth>
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                size="small"
                name="password"
                label="Password"
                autoComplete="off"
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="small"
              color="success"
            >
              Sign In
            </Button>
          </Box>
          <Alerts
            open={alert.open}
            message={alert.message}
            severity={alert.severity}
            onClose={handleCloseAlert}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
