import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import { Controller } from 'react-hook-form';

dayjs.extend(utc);

export default function CustomDatePicker({ ...props }) {
  const today = dayjs().startOf('day');

  const shouldDisableDate = (date) => {
    return date.isSame(today, 'day');
  };
  return (
    <React.Fragment>
      <FormControl fullWidth className={props.error ? 'Text-error-field' : ''}>
        <Controller
          name={props.name}
          control={props.control}
          rules={{ required: true }}
          render={({ field }) => (
            <LocalizationProvider
              dateLibInstance={dayjs}
              adapterLocale="de"
              dateAdapter={AdapterDayjs}
            >
              <DemoContainer
                components={[
                  'DatePicker',
                  'MobileDatePicker',
                  'DesktopDatePicker',
                ]}
              >
                <DemoItem>
                  <DatePicker
                    className={props.className}
                    {...field}
                    label={props.label}
                    value={field.value ? dayjs(field.value).utc() : dayjs(null)}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: props.error ? true : false,
                        helperText: props.error ? props.error.message : '',
                      },
                    }}
                    disableFuture={props.disableFuture}
                    disablePast={props.disablePast}
                    format={props.format}
                    shouldDisableDate={shouldDisableDate}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          )}
        />
      </FormControl>
    </React.Fragment>
  );
}
