import React from 'react';
import { TextField } from '@mui/material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { OutlinedInput, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import { RESOURCES } from '../../../helpers/SubscriptionConfig';
import { SUBJECT, ABILITIES } from '../../../config/permissionAbility.config';

const EditPermission = ({
  id,
  errors,
  validate,
  roleName,
  setRoleName,
  roleDescription,
  setRoleDescription,
  roleAbilities,
  setRoleAbilities,
  checked,
  setChecked,
  handleUpdate,
  handleClose,
  setEditId,
}) => {
  const handleSubmit = () => {
    if (
      Object.keys(errors).length === 0 &&
      Object.keys(roleName, roleDescription, roleAbilities, checked).length !==
        0
    )
      console.log('Enter the handleSubmit');
    return handleUpdate(id, roleName, roleDescription, roleAbilities, checked);
  };

  const handleCancel = () => {
    handleClose();
    setEditId(null);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // function getStyles(name, theme) {
  //   return {
  //     fontWeight:
  //       urls.indexOf(name) === -1
  //         ? theme.typography.fontWeightRegular
  //         : theme.typography.fontWeightMedium,
  //   };
  // }

  const theme = useTheme();

  const handleResourcesChange = (event, index, dropdownName) => {
    const value = event.target.value;

    // Create a copy of the existing roleAbilities array
    const newSelectedValues = [...roleAbilities];

    // Update the subject value for the specified index
    newSelectedValues[index] = {
      ...newSelectedValues[index],
      [dropdownName]: value,
      action: [], // Reset the action array when subject changes
    };

    // Set the modified array to state
    setRoleAbilities(newSelectedValues);

    validate(event, 'subject', event.target.value, index);
  };

  const handleUrlChanges = (event, index, dropdownName) => {
    const value = event.target.value;
    const newSelectedValues = [...roleAbilities];

    // Update the action array for the specified subject index
    newSelectedValues[index][dropdownName] = value;

    setRoleAbilities(newSelectedValues);

    validate(event, 'action', event.target.value, index);
  };

  const handleAddDuplicateFields = (event, index) => {
    console.log('INDEX', index);
    const lastValue = roleAbilities[roleAbilities.length - 1];
    console.log('LASTVALUE', lastValue);
    if (lastValue.subject && lastValue.action.length > 0) {
      setRoleAbilities([...roleAbilities, { subject: '', action: [] }]);
    } else if (lastValue.subject === '' && index === roleAbilities.length - 1) {
      validate(event, 'subject', event.target.value, index); // Pass the index parameter to the validate function
    } else if (
      lastValue.action.length === 0 &&
      index === roleAbilities.length - 1
    ) {
      validate(event, 'action', event.target.value, index); // Pass the index parameter to the validate function
    } else {
      return 0;
    }
  };

  const handleRemoveDropdown = (indexToRemove) => {
    setRoleAbilities(
      roleAbilities.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleRoleChange = (event) => {
    setRoleName(event.target.value);
    validate(event, 'roleName', event.target.value);
  };

  const Reviewer = 'reviewer';
  const Approver = 'approver';
  const Treasurer = 'treasurer';
  const CommitteeHead = 'committee head';

  return (
    <React.Fragment>
      <DialogTitle className="dialog-header">
        <p className="dialog-header-text">Edit Permission</p>
        <CloseIcon onClick={handleCancel} className="dialog-closeicon" />
      </DialogTitle>
      <DialogContent>
        <div id="myTextBox" className="custom-textbox">
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small-label">RoleName</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={roleName}
              label="Rolename"
              onChange={handleRoleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={Reviewer}>Reviewer</MenuItem>
              <MenuItem value={Approver}>Approver</MenuItem>
              <MenuItem value={Treasurer}>Treasurer</MenuItem>
              <MenuItem value={CommitteeHead}>Committee Head</MenuItem>
            </Select>
          </FormControl>
          {errors.permissionname && <h6>{errors.permissionname}</h6>}
        </div>
        <div id="myTextBox" className="custom-textbox">
          <TextField
            style={{ height: '40px' }}
            fullWidth
            size="small"
            label="DescriptionName"
            name="roleDescription"
            value={roleDescription}
            onChange={(e) => setRoleDescription(e.target.value)}
            variant="outlined"
          />
        </div>
        {roleAbilities.map((value, index) => (
          <div className="dropdown-container">
            <div className="custom-textbox">
              <FormControl size="small">
                <InputLabel id={`dropdown-label-${index}-1`}>
                  Resources {index + 1}
                </InputLabel>
                <Select
                  labelId={`dropdown-label-${index}-1`}
                  id={`dropdown-${index}-1`}
                  name="subject"
                  style={{ width: '150px', height: '40px' }}
                  value={value.subject}
                  onChange={(e) => handleResourcesChange(e, index, 'subject')}
                  label="Dropdown 1"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {SUBJECT.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      disabled={roleAbilities.some(
                        (selected) => selected.subject === name
                      )}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {errors[`access${index}`] && (
                  <h6>{errors[`access${index}`]}</h6>
                )}
              </FormControl>
            </div>
            <div className="custom1-textbox">
              <FormControl size="small">
                <InputLabel id={`dropdown-label-${index}-1`}>
                  Access {index + 1}
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  style={{ width: '318px', height: '40px' }}
                  value={value.action}
                  name="action"
                  onChange={(e) => handleUrlChanges(e, index, 'action')}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Access" />
                  }
                  renderValue={(selected) => (
                    <Box>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {/* {getOptionsForaction(value.subject)} */}
                  {ABILITIES.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {errors[`url${index}`] && <h6>{errors[`url${index}`]}</h6>}
              </FormControl>
            </div>
            <div className="custom1-icon">
              <AddIcon
                cursor="pointer"
                style={{ color: 'green', fontSize: '30px' }}
                onClick={(event) => handleAddDuplicateFields(event, index)}
              />
              {index !== 0 && (
                <RemoveIcon
                  cursor="pointer"
                  style={{ color: 'red', fontSize: '30px' }}
                  onClick={() => handleRemoveDropdown(index)}
                />
              )}
            </div>
          </div>
        ))}
        <div className="custom-textbox">
          <Switch
            checked={checked}
            name="checked"
            onChange={(e) => setChecked(e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          {checked ? 'Active' : 'InActive'}
        </div>
        <div className="custom-submit">
          <DialogActions>
            <Link component="button" variant="body2" onClick={handleCancel}>
              Cancel
            </Link>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              style={{ textTransform: 'none' }}
              disabled={
                !roleName || roleAbilities.some((value) => !value.action.length)
              }
            >
              Update Permission
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </React.Fragment>
  );
};
export default EditPermission;
