import React, { useState } from 'react';
import AddPermission from './AddPermission';
import { omit } from 'lodash';
import EditPermission from './EditPermission';

function PermissionValidation(props) {
  const id =
    props.currentUser && props.currentUser.id
      ? props.currentUser.id
      : undefined;
  const [roleName, setRoleName] = useState(
    id ? props.currentUser.roleName : ''
  );
  const [roleDescription, setRoleDescription] = useState(
    id ? props.currentUser.roleDescription : ''
  );
  const [roleAbilities, setRoleAbilities] = useState(
    id ? props.currentUser.roleAbilities : [{ subject: '', action: [] }]
  );
  const [checked, setChecked] = useState(id ? props.currentUser.checked : true);

  const handleForm = () => {
    props.addUser(roleName, roleDescription, roleAbilities, checked);
    props.handleClose();
  };

  const permissionname = (name, value) => {
    if (value.length === 0) {
      // we will set the error state
      setErrors({
        ...errors,
        permissionname: 'The field is required',
      });
    } else if (!/^[a-zA-Z\s!@#$_-]+$/.test(value)) {
      setErrors({
        ...errors,
        permissionname: 'Give alphabatic only',
      });
    } else if (value.length >= 30) {
      setErrors({
        ...errors,
        permissionname: 'Name cannot exceed 30 characters',
      });
    } else {
      // set the error state empty or remove the error for username input
      //omit function removes/omits the value from given object and returns a new object
      let newObj = omit(errors, 'permissionname');
      setErrors(newObj);
    }
  };
  // const url = (name, value) => {
  //   console.log("URL");
  //   if (!value || value.length === 0) {
  //     // we will set the error state
  //     setErrors({
  //       ...errors,
  //       url: "The field is required",
  //     });
  //   } else {
  //     // set the error state empty or remove the error for username input
  //     //omit function removes/omits the value from given object and returns a new object
  //     let newObj = omit(errors, "url");
  //     setErrors(newObj);
  //   }
  // };

  const url = (name, value, index) => {
    console.log('URL');
    if (!value || value.length === 0) {
      // Set the error state for the specific index
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`url${index}`]: 'The field is required',
      }));
    } else {
      // Remove the error state for the specific index
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`url${index}`]: '',
      }));
    }
  };

  const access = (name, value, index) => {
    console.log('URL');
    if (!value || value.length === 0) {
      // Set the error state for the specific index
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`access${index}`]: 'The field is required',
      }));
    } else {
      // Remove the error state for the specific index
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`access${index}`]: '',
      }));
    }
  };

  // const access = (name, value) => {
  //   console.log("access");
  //   if (!value || value.length === 0) {
  //     // we will set the error state
  //     setErrors({
  //       ...errors,
  //       access: "The field is required",
  //     });
  //   } else {
  //     // set the error state empty or remove the error for username input
  //     //omit function removes/omits the value from given object and returns a new object
  //     let newObj = omit(errors, "access");
  //     setErrors(newObj);
  //   }
  // };

  function handleSave(id, roleName, roleDescription, roleAbilities, checked) {
    console.log('Enter the validation handleUpdate');
    return props.handleUpdate(
      id,
      roleName,
      roleDescription,
      roleAbilities,
      checked
    );
  }
  const [errors, setErrors] = useState({});
  const validate = (event, name, value, index) => {
    //A function to validate each input values
    switch (name) {
      case 'roleName':
        permissionname(name, value);
        break;
      case 'subject':
        access(name, value, index);
        break;
      case 'action':
        url(name, value, index);
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      {props.setEditId ? (
        <EditPermission
          id={id}
          validate={validate}
          handleClose={props.handleClose}
          handleUpdate={handleSave}
          setEditId={props.setEditId}
          errors={errors}
          roleName={roleName}
          setRoleName={setRoleName}
          roleDescription={roleDescription}
          setRoleDescription={setRoleDescription}
          roleAbilities={roleAbilities}
          setRoleAbilities={setRoleAbilities}
          setChecked={setChecked}
          checked={checked}
        />
      ) : (
        <AddPermission
          setAdd={props.setAdd}
          checked={checked}
          url={url}
          validate={validate}
          roleName={roleName}
          roleDescription={roleDescription}
          roleAbilities={roleAbilities}
          setRoleAbilities={setRoleAbilities}
          setRoleName={setRoleName}
          setRoleDescription={setRoleDescription}
          subject={roleAbilities[0].subject}
          action={roleAbilities[0].action}
          setChecked={setChecked}
          setErrors={setErrors}
          errors={errors}
          handleForm={handleForm}
          handleClose={props.handleClose}
        />
      )}
    </React.Fragment>
  );
}

export default PermissionValidation;

// !/^[A-Za-z]+$/i.test(value) && !/^[!@#$%^&*(),.?":{}|<>]+$/.test(value)
